import React, { useEffect, useState } from 'react'
import { ORow, OCol } from '@dnvgl-onefoundation/onedesign-react'
import { Input } from '@/components/helpers'
import { UpdateOnshoreCostCalculationViewModel } from '@/interfaces'
import { helper } from '@/utils'
import {
  onshoreDisassemblyCostSections,
  onshoreMaterialDisposalCostSections,
  onshoreBladesCostSections,
  onshoreFoundationCostSections,
  onshoreCablesCostSections,
  onshoreOtherOnSiteRemovalCostSections,
  onshoreAccessRoadRemovalCostSections,
  onshoreReinstateCostSections,
  onshoreOverheadCostSections,
  onshoreScrapCostSections,
  onshoreRemovalCostSections,
  onshoreCraneRemovalCostSections
} from './consts'
import { useScenario } from '@/hooks'
import { costLabels } from '@/utils/costNames'

interface Props {
  payload: UpdateOnshoreCostCalculationViewModel
  onUpdate?: (payload: UpdateOnshoreCostCalculationViewModel) => void
  includeScrapValue?: boolean
}
interface HeadlineProps {
  currencySymbol?: string
}
interface SummaryProps {
  value?: number
}
interface CostSection {
  label: string
  sectionName: string
  costSections: any[]
  payload: UpdateOnshoreCostCalculationViewModel
  onUpdate?: (payload: UpdateOnshoreCostCalculationViewModel) => void
  symbol?: string
}
const { getArraySum, formatCurrencyMask } = helper

const DescriptionHeadline = ({ currencySymbol }: HeadlineProps) => (
  <tr className="bg-light border">
    <th className="p-1 border">Description</th>
    <th className="p-1 border">{currencySymbol}/Wind farm</th>
  </tr>
)
const SummaryLine = ({ value }: SummaryProps) => (
  <tr className="bg-light border">
    <th className="p-1 border">Total</th>
    <th className="p-1 border">{value}</th>
  </tr>
)

const CostSection = ({
  label,
  sectionName,
  costSections,
  payload,
  onUpdate,
  symbol
}: CostSection) => (
  <>
    <tr>
      <th className="bg-dark fg-white p-2" colSpan={2}>
        {label}
      </th>
    </tr>
    <DescriptionHeadline currencySymbol={symbol} />
    {costSections.map(i => (
      <tr key={i.propertyName}>
        <td className="p-0 pl-1 border">{i.label}</td>
        <td className="p-0 border">
          <Input
            noBorder
            className="d-flex m-0 p-1"
            propertyName={i.propertyName}
            defaultValue={
              Math.round(
                (
                  payload[
                    sectionName as keyof UpdateOnshoreCostCalculationViewModel
                  ] as any
                )?.[i.propertyName]
              ) || 0
            }
            type="number"
            onChange={(n, v) =>
              onUpdate?.({
                ...payload,
                [sectionName as keyof UpdateOnshoreCostCalculationViewModel]:
                  {
                    ...payload[
                      sectionName as keyof UpdateOnshoreCostCalculationViewModel
                    ],
                    [n]: v
                  } || 0
              })
            }
          />
        </td>
      </tr>
    ))}
    <SummaryLine
      value={Math.round(
        getArraySum(
          Object.values(
            payload[sectionName as keyof UpdateOnshoreCostCalculationViewModel]
          ) || 0
        )
      )}
    />
  </>
)

const AdminUpdateCalculationResultsOnshore = ({
  payload,
  onUpdate,
  includeScrapValue
}: Props) => {
  const [total, setTotal] = useState(0)
  const { scenario } = useScenario()

  useEffect(() => {
    setTotal(
      getArraySum([
        getArraySum(Object.values(payload.disassemblyCosts)) || 0,
        getArraySum(Object.values(payload.removalOfTurbinesCosts)) || 0,
        getArraySum(Object.values(payload.materialDisposalCosts)) || 0,
        getArraySum(Object.values(payload.bladesDisposalCosts)) || 0,
        getArraySum(Object.values(payload.foundationsDisposalCosts)) || 0,
        getArraySum(Object.values(payload.cableRemovalCosts)) || 0,
        getArraySum(
          Object.values(payload.removalOfOtherOnSiteConstructionsCosts)
        ) || 0,
        getArraySum(Object.values(payload.accessRoadsRemovalCosts)) || 0,
        getArraySum(Object.values(payload.cranePadsRemovalCosts)) || 0,
        getArraySum(Object.values(payload.reinstateCosts)) || 0,
        getArraySum(Object.values(payload.projectOverheadsCosts)) || 0,
        getArraySum(Object.values(payload.metalScrapValues)) || 0
      ]) - getArraySum(Object.values(payload.metalScrapValues)) || 0
    )
  }, [payload])

  return payload ? (
    <ORow>
      <OCol md="12">
        <table className="w-100 border">
          <tbody>
            <CostSection
              label={costLabels.disassemblyCosts}
              sectionName="disassemblyCosts"
              costSections={onshoreDisassemblyCostSections}
              payload={payload}
              onUpdate={onUpdate}
              symbol={scenario?.currency.currencyInformation.symbol}
            />
            <CostSection
              label={costLabels.removalOfTurbinesCosts}
              sectionName="removalOfTurbinesCosts"
              costSections={onshoreRemovalCostSections}
              payload={payload}
              onUpdate={onUpdate}
              symbol={scenario?.currency.currencyInformation.symbol}
            />
            <CostSection
              label={costLabels.materialDisposalCosts}
              sectionName="materialDisposalCosts"
              costSections={onshoreMaterialDisposalCostSections}
              payload={payload}
              onUpdate={onUpdate}
              symbol={scenario?.currency.currencyInformation.symbol}
            />
            <CostSection
              label={costLabels.bladesDisposalCosts}
              sectionName="bladesDisposalCosts"
              costSections={onshoreBladesCostSections}
              payload={payload}
              onUpdate={onUpdate}
              symbol={scenario?.currency.currencyInformation.symbol}
            />
            <CostSection
              label={costLabels.foundationsDisposalCosts}
              sectionName="foundationsDisposalCosts"
              costSections={onshoreFoundationCostSections}
              payload={payload}
              onUpdate={onUpdate}
              symbol={scenario?.currency.currencyInformation.symbol}
            />
            <CostSection
              label={costLabels.cableRemovalCosts}
              sectionName="cableRemovalCosts"
              costSections={onshoreCablesCostSections}
              payload={payload}
              onUpdate={onUpdate}
              symbol={scenario?.currency.currencyInformation.symbol}
            />
            <CostSection
              label={costLabels.removalOfOtherOnSiteConstructions}
              sectionName="removalOfOtherOnSiteConstructionsCosts"
              costSections={onshoreOtherOnSiteRemovalCostSections}
              payload={payload}
              onUpdate={onUpdate}
              symbol={scenario?.currency.currencyInformation.symbol}
            />
            <CostSection
              label={costLabels.accessRoadsRemovalCosts}
              sectionName="accessRoadsRemovalCosts"
              costSections={onshoreAccessRoadRemovalCostSections}
              payload={payload}
              onUpdate={onUpdate}
              symbol={scenario?.currency.currencyInformation.symbol}
            />
            <CostSection
              label={costLabels.cranePadsRemovalCosts}
              sectionName="cranePadsRemovalCosts"
              costSections={onshoreCraneRemovalCostSections}
              payload={payload}
              onUpdate={onUpdate}
              symbol={scenario?.currency.currencyInformation.symbol}
            />
            <CostSection
              label={costLabels.reinstateCosts}
              sectionName="reinstateCosts"
              costSections={onshoreReinstateCostSections}
              payload={payload}
              onUpdate={onUpdate}
              symbol={scenario?.currency.currencyInformation.symbol}
            />
            <CostSection
              label={costLabels.onshoreProjectOverheadCosts}
              sectionName="projectOverheadsCosts"
              costSections={onshoreOverheadCostSections}
              payload={payload}
              onUpdate={onUpdate}
              symbol={scenario?.currency.currencyInformation.symbol}
            />
            <CostSection
              label={costLabels.metalScrapValues}
              sectionName="metalScrapValues"
              costSections={onshoreScrapCostSections}
              payload={payload}
              onUpdate={onUpdate}
              symbol={scenario?.currency.currencyInformation.symbol}
            />
            <tr>
              <th className="bg-dark fg-white p-2">
                {includeScrapValue ? 'Net Total' : 'Total'}
              </th>
              <th className="bg-dark fg-white p-2">
                {formatCurrencyMask(
                  total,
                  scenario?.currency.currencyInformation!
                )}
              </th>
            </tr>
          </tbody>
        </table>
      </OCol>
    </ORow>
  ) : null
}

export default React.memo(AdminUpdateCalculationResultsOnshore)
