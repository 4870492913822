import SuperscriptUnit from '@/components/helpers/SuperscriptUnit'
import {
  AnchorMaterial,
  CableMaterial,
  FoundationType,
  GroupType,
  MooringLineMaterial,
  OnboardingRole,
  ProjectType,
  SubscriptionTier,
  TurbinePart
} from '@/interfaces'

const isProd = window.location.host === 'rewind.dnv.com'
const isStag = window.location.host === 'wa-rewind-staging-we.azurewebsites.net'

const urls = {
  home: '/',
  about: '/about',
  admin: '/admin',
  adminTurbinesImport: '/admin/turbines-import',
  adminProvidersImport: '/admin/providers-import',
  howItWorks: '/how-it-works',
  settings: '/settings',
  settingsDefault: '/settings/default',
  settingsCountries: '/settings/countries',
  settingsProjects: '/settings/projects',
  settingsScenarios: '/settings/scenarios',
  adminUserGroups: 'admin/UserGroups',
  projects: '/projects',
  providers: '/providers',
  scenarios: '/decommissioning-scenarios',
  turbines: '/turbines',
  reports: '/reports'
}

const endpoints = {
  currentUser: '/api/Account/CurrentUser',
  my: '/my',
  userAdministration: '/api/UserAdministration',
  groupsAdmin: '/api/GroupAdministration/Groups',
  groups: '/api/Groups',
  teams: '/api/Teams',
  projects: '/api/Projects',
  providers: '/api/Providers',
  turbines: '/api/Turbines',
  userTurbines: '/api/Turbines/OwnedByUser',
  files: '/files',
  countries: '/api/Countries',
  currencies: '/api/Currencies/GetAll',
  scenarioCurrency: '/api/Currencies/GetInfo',
  scenarios: '/api/Scenarios',
  onshoreCostCalculations: '/api/OnshoreCostCalculations',
  offshoreBottomFixedCostCalculations: '/api/OffshoreFixedCostCalculations',
  offshoreFloatingCostCalculations: '/api/OffshoreFloatingCostCalculations',
  circularity: '/api/Circularity',
  teamsNotifications: '/api/TeamsNotifications',
  configurations: '/api/CostCalculationConfigurations',
  onboarding: '/api/Onboarding'
}

const icons = {
  calculator: 'fal fa-calculator-alt',
  add: 'fal far fa-plus',
  info: 'fas fa-info-square',
  infoCircle: 'fal fa-info-circle',
  infrastructure: 'fas fa-chart-network',
  materialBreakdown: 'fas fa-chart-pie',
  print: 'fal fa-print',
  providersMap: 'fal fa-map-marked-alt',
  projectsGrid: 'fal fa-grip-horizontal',
  scenario: 'fal fa-code-branch',
  toolbox: 'fas fa-toolbox',
  windTurbine: 'fal fa-wind-turbine',
  multiple: 'fas fa-grip-horizontal',
  analytics: 'fas fa-analytics',
  users: 'fas fa-users',
  import: 'fas fa-file-import',
  download: 'fas fa-cloud-download',
  upload: 'fas fa-cloud-upload',
  cancel: 'fas fa-times text-danger',
  metalProcessingLight: 'fal fa-layer-group',
  bladeProcessingLight: 'fal fa-fan',
  concreteProcessingLight: 'fal fa-cube',
  marker: 'fas fa-map-marker-alt',
  hint: 'fas fa-lightbulb',
  pdf: 'far fa-file-pdf',
  delete: 'far fa-trash-alt'
}

const config = {
  version: '1.0.0',
  appName: 'ReWind',
  appFullName: 'ReWind',
  urls,
  endpoints,
  dateFormat: 'DD MMMM YYYY',
  htmlDateFormat: 'YYYY-MM-DD',
  dateTimeFormat: 'DD MMM YYYY, HH:mm',
  shortDateFormat: 'MM/DD/YYYY',
  authCheckInterval: 1860000,
  staticAssetsUrlBlob: `https://${
    isProd ? 'bsrewinddeprod' : 'bsrewinddevwe'
  }.blob.core.windows.net/static`,
  showNewSubstations: false,
  hideFloatingModel: true,
  amplitudeApiKey: '3c89d01060177a837f46ae69d297d787',
  templates: {
    providers: '/files/templates/ReWind_Providers_rev5.xlsm',
    turbines: '/files/templates/ReWind_Turbines_rev6.xlsx'
  },
  support: {
    to: 'rewind@dnv.com',
    cc: ''
  },
  missingValueCharacter: '—',
  genericGuid: '00000000-0000-0000-0000-000000000000',
  admin: {
    maxCompanyNameLength: 100
  },
  defaultCountry: 'Norway',
  defaultSelectOption: { text: 'Please select', value: false, disabled: true },
  charts: {
    legend: {
      position: 'bottom',
      labels: {
        padding: 8,
        font: {
          size: 16
        }
      }
    }
  },
  links: {
    header: {
      logIn: '/api/Account/SignIn',
      logOut: '/api/Account/SignOut',
      signUp: `https://id.veracity.com/sign-up?return-url=${window.location.origin}/api/Account/SignIn`,
      notifications: 'https://services.veracity.com/Notifications'
    },
    footer: {
      big: [
        {
          title: 'Product',
          links: [
            {
              name: 'About',
              path: urls.about,
              guestUsers: true
            }
          ]
        },
        {
          title: 'Legal',
          links: [
            {
              name: 'Privacy statement',
              path: '/privacy',
              guestUsers: true
            },
            {
              name: 'Terms of use',
              path: '/tou',
              guestUsers: true
            },
            {
              name: 'Cookies policy',
              path: '/cookies',
              guestUsers: true
            }
          ]
        }
      ]
    }
  },
  calculations: {
    supportedProjectTypes: [] as ProjectType[],
    chartColors: {
      disassembly: '#99D9F0',
      removal: '#66C5E9',
      blade: '#33B2E1',
      scrap: '#36842D',
      total: '#003591',
      disassemblyCost: '#99d9f0',
      removalCost: '#99d9f0',
      materialDisposalCost: '#99d9f0',
      bladeDisposalCost: '#99d9f0',
      turbineFoundationsDisposalCost: '#99d9f0',
      cableRemovalCost: '#99d9f0',
      removalOfArrayCables: '#99d9f0',
      removalOfExportCable: '#99d9f0',
      removalOfOtherOnSiteConstructions: '#99d9f0',
      accessRoadsRemovalCost: '#99d9f0',
      cranePadsRemovalCost: '#99d9f0',
      reinstateCost: '#99d9f0',
      projectOverheadCost: '#99d9f0',
      reserve: '#387478',
      disassemblyCosts: '#99d9f0',
      removalOfTurbinesCosts: '#99d9f0',
      materialDisposalCosts: '#99d9f0',
      bladesDisposalCosts: '#99d9f0',
      foundationsDisposalCosts: '#99d9f0',
      cableRemovalCosts: '#99d9f0',
      accessRoadsRemovalCosts: '#99d9f0',
      cranePadsRemovalCosts: '#99d9f0',
      reinstateCosts: '#99d9f0',
      onshoreProjectOverheadCosts: '#99d9f0',
      metalScrapValues: '#36842D',
      contingencyAllocation: '#387478',
      totalCosts: '#003591',
      netTotalCosts: '#003591'
    }
  },
  routes: {
    guest: [],
    authenticated: [
      {
        name: 'Home',
        path: '/'
      },
      {
        name: 'Turbines',
        path: urls.turbines
      },
      {
        name: 'Projects',
        path: urls.projects
      },
      {
        name: 'Scenarios',
        path: urls.scenarios
      },
      {
        name: 'Providers',
        path: urls.providers
      },
      {
        name: 'Settings',
        path: urls.settings
      },
      {
        name: 'About',
        path: urls.about
      }
    ],
    admin: [
      {
        name: '(Admin)',
        path: urls.admin
      }
    ]
  },
  materialBreakdown: {
    parts: ['blades', 'hub', 'nacelle', 'tower', 'total'] as TurbinePart[],
    turbineFields: [
      {
        name: 'steel',
        heading: 'Steel',
        color: '#A1AAE6'
      },
      {
        name: 'castIron',
        heading: 'Cast Iron',
        color: '#B56700'
      },
      {
        name: 'copper',
        heading: 'Copper',
        color: '#15C2BB'
      },
      {
        name: 'concrete',
        heading: 'Concrete',
        color: '#D5CFCF'
      },
      {
        name: 'aluminium',
        heading: 'Aluminium',
        color: '#2B6173'
      },
      {
        name: 'rareEarths',
        heading: 'Rare earths',
        color: '#0F204B'
      },
      {
        name: 'glassFibre',
        heading: 'Glass fibre',
        color: '#009FDA'
      },
      {
        name: 'epoxyResin',
        heading: 'Epoxy Resin',
        color: '#99D9F0'
      },
      {
        name: 'purpvcFoam',
        heading: 'PUR/PVC Foam',
        color: '#F2E6D5'
      },
      {
        name: 'balsaWood',
        heading: 'Balsa Wood',
        color: '#9A7550'
      },
      {
        name: 'epoxyGelcoat',
        heading: 'Epoxy Gelcoat',
        color: '#988F86'
      },
      {
        name: 'silicon',
        heading: 'Silicon',
        color: '#FFF377'
      },
      {
        name: 'other',
        heading: 'Other',
        color: '#3F9C35'
      },
      {
        name: 'total',
        heading: 'Total',
        color: '#003591'
      }
    ]
  },
  projects: {
    requiredFields: {
      onshore: [
        'accessRoadLengthKm',
        'accessRoadWidthM',
        'cranePadLengthM',
        'cranePadLengthM',
        'cranePadWidthM',
        'foundationVolumeM3'
      ]
    },
    selectOptions: {
      anchor: [
        { text: 'Cast iron', value: AnchorMaterial.CastIron },
        { text: 'Steel', value: AnchorMaterial.Steel }
      ],
      foundationType: [
        { text: 'Jacket', value: FoundationType.Jacket },
        { text: 'Monopile', value: FoundationType.Monopile },
        { text: 'Gravity based', value: FoundationType.GravityBased },
        {
          text: 'Suction bucket jacket',
          value: FoundationType.SuctionBucketJacket
        }
      ],
      cableMaterial: [
        { text: 'Aluminium', value: CableMaterial.Aluminium },
        { text: 'Copper', value: CableMaterial.Copper }
      ],
      mooringLineMaterial: [
        { text: 'Steel', value: MooringLineMaterial.Steel },
        { text: 'Polyester', value: MooringLineMaterial.Polyester },
        { text: 'Nylon', value: MooringLineMaterial.Nylon }
      ],
      cableSize: [
        10, 12, 17.5, 20, 22, 30, 33, 38, 45, 66, 110, 132, 150, 220, 275, 320
      ].map(value => ({
        text: `${value}kV`,
        value
      })),
      numberOfSubstations: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 }
      ],
      offshoreSubstationType: [
        { text: 'HVAC', value: 'HVAC' },
        { text: 'HVDC', value: 'HVDC' }
      ]
    }
  },
  providers: {
    websiteAddress: 'https://www.example.com'
  },
  icons,
  inputLabels: {
    accessRoadLengthKm: 'Access road length (km)',
    accessRoadWidthM: 'Access road width (m)',
    cranePadLengthM: 'Crane pad length (m)',
    cranePadWidthM: 'Crane pad width (m)',
    cranePadDepthM: 'Crane pad depth (m)',
    foundationVolumeM3: (
      <span>Foundation volume ({<SuperscriptUnit unit="m" power={3} />})</span>
    ),
    hasHvSubstation: 'Is there an on-site substation?'
  },
  mapbox: {
    apiUrl: 'https://api.mapbox.com',
    europeViewState: {
      longitude: 10,
      latitude: 55,
      zoom: 2
    },
    publicAccessToken: `pk.${
      isProd || isStag
        ? 'eyJ1IjoicmV3aW5kLWRudiIsImEiOiJjbGdjY3Y5M2kwZDR1M29ud3JqajQ2eTZ0In0.XcLFh_2E7ihtKRPcA0qYdg'
        : 'eyJ1Ijoic3Nva3VyZW5rbyIsImEiOiJjbGR1OHo1aW4wM2k3M3BtbWdta3VxbGlyIn0.FTMP1tbGUsAEk8eArGxhhA'
    }`,
    mapStyle: 'mapbox://styles/ssokurenko/ckok1n8o404a617pbh3obclpb',
    minZoom: 2,
    maxZoom: 22
  },
  messages: {
    bladeSparMaterial:
      'If the blade spar material is not known it is best to assume Glass Fiber.',
    underConstruction:
      'This feature is under construction and will be available soon.',
    infoWillBeLost:
      'All data currently stored data will be permanently lost. Please be aware that this action cannot be undone.',
    turbineNameHelpText:
      "Please provide an easily identifiable and distinguishable name for your turbine. For example, you could follow this naming convention: 'Manufacturer_Model_RatedPower_HubHeight'."
  },
  fileImport: {
    excelExtentions: ['xlsm', 'xlsx']
  },
  protectedContent: {
    localStorageRecordsToRemove: [
      'ProvidersPage-rowsPerPage',
      'useProviders-selectedTypes',
      'useProviders-selectedMatrials',
      'useProviders-selectedServices'
    ]
  },
  groups: {
    unassignedGroup: {
      id: 'Unassigned',
      name: 'Unassigned',
      companyId: 'Unassigned',
      isInternal: false,
      type: GroupType.Company
    }
  },
  subscriptionTierOptions: [
    { text: 'NoAccess', value: SubscriptionTier.NoAccess },
    { text: 'Trial', value: SubscriptionTier.Trial },
    { text: 'Permanent', value: SubscriptionTier.Permanent }
  ],
  onboarding: {
    remindLaterDelay: 60 * 60 * 24 * 1000, // 24 hours
    images: {
      1: 'step-1-2.jpg',
      2: 'step-1-2.jpg',
      3: 'step-3.png',
      4: 'step-4.png',
      5: 'step-5.png',
      6: 'step-6.png',
      7: 'step-7.png',
      8: 'step-8.svg'
    },
    roleOptions: [
      {
        text: 'Asset management',
        value: OnboardingRole.AssetManagement
      },
      {
        text: 'Production and development',
        value: OnboardingRole.ProductionAndDevelopment
      },
      {
        text: 'Sustainability',
        value: OnboardingRole.Sustainability
      },
      {
        text: 'Finance',
        value: OnboardingRole.Finance
      },
      {
        text: 'Procurement',
        value: OnboardingRole.Procurement
      }
    ],
    goalOptions: [
      {
        text: 'Understand the material breakdown of my wind farm',
        value: 'understandMaterialBreakdown'
      },
      {
        text: 'Understand decommissioning costs',
        value: 'understandDecommissioningCosts'
      },
      {
        text: 'Estimate turbine residual value',
        value: 'estimateTurbineResidualValue'
      },
      {
        text: 'Understand the circularity of my wind farm',
        value: 'understandCircularity'
      },
      {
        text: 'Discover new recycling methods and facilities',
        value: 'discoverNewRecyclingMethodsAndFacilities'
      }
    ]
  },
  rowsPerPageOptions: [10, 20, 50, 100, 300]
}

export default config
