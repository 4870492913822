import React, { ReactNode } from 'react'
import { Tooltip } from '@mui/joy'
import { InfoOutlined } from '@mui/icons-material'

interface Props {
  message?: string | ReactNode
  className?: string
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}

const TooltipHelpText = ({ message, className, placement = 'top' }: Props) =>
  message ? (
    <Tooltip
      arrow
      variant="soft"
      color="primary"
      title={message || ''}
      placement={placement}
      sx={{ maxWidth: '250px' }}>
      <InfoOutlined
        sx={{ margin: '0 4px', color: '#636B74' }}
        className={className}
      />
    </Tooltip>
  ) : null

export default React.memo(TooltipHelpText)
