import React, { useEffect, useState } from 'react'
import { Text, View } from '@react-pdf/renderer'
import {
  CurrencyInformationViewModel,
  OnshoreCostCalculationViewModel,
  ReportSectionCode,
  ScenarioItemViewModel
} from '@/interfaces'
import ExpandedCostTable from '@/components/pdf/ExpandedCostTable'
import { helper } from '@/utils'

interface Props {
  printSections: number[]
  costScenarioBreakdownItems?: OnshoreCostCalculationViewModel & {
    [key: string]: any
  }
  styles?: any
  texts?: any
  setPages?: any
  currencyInformation?: CurrencyInformationViewModel
  scenario?: ScenarioItemViewModel
}
const { formatCurrencyMask } = helper

const ReportAppendixPage: React.FC<Props> = ({
  costScenarioBreakdownItems,
  styles,
  setPages,
  currencyInformation,
  scenario,
  printSections
}) => {
  const [page, setPage] = useState<number | undefined>()
  useEffect(() => {
    page && setPages({ [ReportSectionCode.Appendix]: page })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])
  const headers: any[] = [
    '',
    `${currencyInformation?.symbol}/Wind Farm`,
    `${currencyInformation?.symbol}/Turbine`,
    `${currencyInformation?.symbol}/MW`
  ]

  return (
    <View>
      <View render={({ pageNumber }) => setPage(pageNumber)} />
      <Text id="appendix" style={styles.pageTitle}>
        {printSections
          .sort()
          .findIndex(value => value === ReportSectionCode.Appendix) + 1}
        . Appendix
      </Text>
      <Text id="appendix" style={styles.subTitle}>
        Appendix 1
      </Text>
      <View style={styles.container}>
        <ExpandedCostTable
          breakPage
          name="Cost Scenario Breakdown"
          costs={costScenarioBreakdownItems}
          valueFormater={formatCurrencyMask}
          currencyInformation={currencyInformation}
          headers={headers}
          scenario={scenario}
        />
      </View>
    </View>
  )
}

export default ReportAppendixPage
