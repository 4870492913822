interface CostLabels {
  [key: string]: string
}

export const costLabels: CostLabels = {
  disassemblyCosts: 'Turbine disassembly cost',
  removalOfTurbinesCosts: 'Turbine removal cost',
  materialDisposalCosts: 'Material disposal cost',
  bladesDisposalCosts: 'Turbine blade disposal cost',
  foundationsDisposalCosts: 'Turbine foundations removal cost',
  cableRemovalCosts: 'Cable removal cost',
  removalOfOtherOnSiteConstructions: 'Removal of other on-site constructions',
  accessRoadsRemovalCosts: 'Access roads removal cost',
  cranePadsRemovalCosts: 'Crane pads removal cost',
  reinstateCosts: 'Reinstate cost',
  onshoreProjectOverheadCosts: 'Project overhead cost',
  metalScrapValues: 'Scrap Value',
  contingencyAllocation: 'Contingency allocation',
  inflation: 'Inflation',
  totalCosts: 'Total decommissioning costs',
  netTotalCosts: 'Net Total decommissioning costs'
}

export const costTooltips: CostLabels = {
  disassemblyCosts:
    'Costs for dismantling blades, rotor, nacelle, and tower, including crane mobilization/demobilization.',
  removalOfTurbinesCosts:
    'Costs for cutting turbines to size, loading onto vehicles, and transporting to processing facilities.',
  materialDisposalCosts:
    'Costs for disposing of aggregates (e.g., crane pads, roads) and reinforced concrete (e.g., foundations).',
  bladesDisposalCosts: 'Costs for wind turbine blade disposal.',
  foundationsDisposalCosts:
    'Costs for breaking reinforced concrete, cutting rebar, loading onto vehicles, and transport to processing.',
  cableRemovalCosts:
    'Costs for trench excavation, cable removal, loading, and transport; includes overhead cable removal if applicable.',
  removalOfOtherOnSiteConstructions:
    'Costs for removing buildings, substations, and met masts.',
  accessRoadsRemovalCosts:
    'Costs for removing roads, geotextiles, transport to processing facilities, and mobilization.',
  cranePadsRemovalCosts:
    'Costs for removing crane pads and geotextiles, transport, and mobilization.',
  reinstateCosts:
    'Costs for importing and distributing soils to restore excavation areas.',
  onshoreProjectOverheadCosts:
    'Costs for permits, insurance, environmental studies, safety equipment, and on-site facilities.',
  metalScrapValues:
    'Estimated residual scrap value of turbine metals and balance of plant.',
  contingencyAllocation:
    'Contingency allocation of total decommissioning costs (excluding overheads) for unforeseen changes or delays.\n',
  inflation:
    'Allocation for inflation, which reflects the year on year increase in labour, material and plant hire costs. See settings panel to change assumptions used.',
  totalCosts: 'Total costs of selected decommissioning scope.',
  netTotalCosts: 'Total project costs minus revenue from scrap value.'
}

export const subCostLabels: CostLabels = {
  disassemblyCostsOfRotors: 'Disassembly costs of rotors',
  disassemblyCostsOfNacelleAndHubs: 'Disassembly costs of nacelle and hubs',
  disassemblyCostsOfTowers: 'Disassembly costs of towers',
  disassemblyCostsOfTransformers: 'Disassembly costs of transformers',
  mobilisationDemobilisationOfCranePersonalOtherEquipmentCosts:
    'Demobilization personal and equipment costs',
  loadingCostsTurbineTowers: 'Loading costs turbine towers',
  transportCostOfTurbineTowers: 'Transport cost of turbine towers',
  loadingCostsTurbineBlades: 'Loading costs of turbine blades',
  transportCostOfTurbineBlades: 'Transport costs of turbine blades',
  loadingCostsOfRemainingNacelleAndGen: 'Loading costs of nacelle and gen',
  transportCostsOfRemainingNacelleAndGen: 'Transport costs of nacelle and gen',
  demobilisationCostsPersonalAndEquipment:
    'Demobilization personal and equipment costs',
  costOfRecyclingAllFoundations: 'Costs of recycling all foundations',
  costForLooseAggregateFromRoads: 'Costs for loose aggregate from roads',
  costForLooseAggregateFromCranePads:
    'Costs of loose aggregate from crane pads',
  breakOutCuttingReBarsRemovalOnSite: 'Break out cutting bars removal on site',
  loadingAggregateOntoTipperTrucks: 'Loading aggregate onto tripper trucks',
  transportAggregateToRecyclingCentre:
    'Transport aggregate to recycling centre',
  demobilisationCostsPersonalEquipment:
    'Demobilization personal and equipment costs',
  diggingOutTheCableCosts: 'Digging out the cables costs',
  reinstatingCableTrenchCosts: 'Reinstating cable trench costs',
  transportCableToRecyclingCentreCosts:
    'Transport cable to recycling centre costs',
  demobilisationPersonalPlusEquipmentCosts:
    'Demobilization personal and equipment costs',
  metMasts: 'Met masts',
  hvSubstations: 'HV substations',
  otherOnSiteBuildings: 'Other on-site buildings',
  rippingRoads: 'Ripping roads',
  roadRemovalOnSite: 'Road removal on site',
  roadRemovalToAggregateRecyclingCentre:
    'Road removal to aggregate recycling centre',
  removingGeotextileUnderneathRoad: 'Removing geotextile underneath road',
  deMobilisationCostsPersonalEquipment:
    'Demobilization personal and equipment costs',
  rippingCranePads: 'Ripping crane pads',
  aggregateRemovalOnSite: 'Aggregate removal on site',
  aggregateRemovalToAggregateRecyclingCentre:
    'Aggregate removal to aggregate recycling centre',
  removingGeotextileUnderneathCranePad:
    'Removing geotextile underneath crane pad',
  importOfSubAndTopSoil: 'Import of sub and top soil',
  distributionOfTopSoilToRequiredAreas:
    'Distribution of top soil to required areas',
  dozeringAndRollingTopSoil: 'Dozering and rolling top soil',
  contractorCosts: 'Contractor costs',
  ownersCosts: 'Owners costs',
  steelScrapValueInTurbines: 'Steel scrap value in turbines',
  copperScrapValueInTurbines: 'Copper scrap value in turbines',
  aluminiumScrapValueInCables: 'Aluminium scrap value in cables',
  steelInTurbineTowers: 'Steel scrap value in towers',
  steelInTurbineNacelles: 'Steel scrap value in nacelles',
  copperInGenerators: 'Cooper scrap value in generators',
  copperInTransformers: 'Cooper scrap value in transformers',
  copperInSubstationsTransformers:
    'Cooper scrap value in substations transformers',
  aluminiumInCables: 'Aluminium scrap value in cables',
  totalCosts: 'Total decommissioning costs',
  netTotalCosts: 'Net Total decommissioning costs',
  contingencyAllocation: 'Contingency allocation'
}
