import { subCostLabels } from '@/utils/costNames'

export const onshoreDisassemblyCostSections = [
  {
    propertyName: 'disassemblyCostsOfRotors',
    label: subCostLabels.disassemblyCostsOfRotors
  },
  {
    propertyName: 'disassemblyCostsOfNacelleAndHubs',
    label: subCostLabels.disassemblyCostsOfNacelleAndHubs
  },
  {
    propertyName: 'disassemblyCostsOfTowers',
    label: subCostLabels.disassemblyCostsOfTowers
  },
  {
    propertyName: 'disassemblyCostsOfTransformers',
    label: subCostLabels.disassemblyCostsOfTransformers
  },
  {
    propertyName:
      'mobilisationDemobilisationOfCranePersonalOtherEquipmentCosts',
    label:
      subCostLabels.mobilisationDemobilisationOfCranePersonalOtherEquipmentCosts
  }
]

export const onshoreRemovalCostSections = [
  {
    propertyName: 'loadingCostsTurbineTowers',
    label: subCostLabels.loadingCostsTurbineTowers
  },
  {
    propertyName: 'transportCostOfTurbineTowers',
    label: subCostLabels.transportCostOfTurbineTowers
  },
  {
    propertyName: 'loadingCostsTurbineBlades',
    label: subCostLabels.loadingCostsTurbineBlades
  },
  {
    propertyName: 'transportCostOfTurbineBlades',
    label: subCostLabels.transportCostOfTurbineBlades
  },
  {
    propertyName: 'loadingCostsOfRemainingNacelleAndGen',
    label: subCostLabels.loadingCostsOfRemainingNacelleAndGen
  },
  {
    propertyName: 'transportCostsOfRemainingNacelleAndGen',
    label: subCostLabels.transportCostsOfRemainingNacelleAndGen
  },
  {
    propertyName: 'demobilisationCostsPersonalAndEquipment',
    label: subCostLabels.demobilisationCostsPersonalAndEquipment
  }
]

export const onshoreMaterialDisposalCostSections = [
  {
    propertyName: 'costOfRecyclingAllFoundations',
    label: subCostLabels.costOfRecyclingAllFoundations
  },
  {
    propertyName: 'costForLooseAggregateFromRoads',
    label: subCostLabels.costForLooseAggregateFromRoads
  },
  {
    propertyName: 'costForLooseAggregateFromCranePads',
    label: subCostLabels.costForLooseAggregateFromCranePads
  }
]
export const onshoreBladesCostSections = [
  {
    propertyName: 'total',
    label: subCostLabels.bladesDisposalCosts
  }
]
export const onshoreFoundationCostSections = [
  {
    propertyName: 'breakOutCuttingReBarsRemovalOnSite',
    label: subCostLabels.breakOutCuttingReBarsRemovalOnSite
  },
  {
    propertyName: 'loadingAggregateOntoTipperTrucks',
    label: subCostLabels.loadingAggregateOntoTipperTrucks
  },
  {
    propertyName: 'transportAggregateToRecyclingCentre',
    label: subCostLabels.transportAggregateToRecyclingCentre
  },
  {
    propertyName: 'demobilisationCostsPersonalEquipment',
    label: subCostLabels.demobilisationCostsPersonalEquipment
  }
]
export const onshoreCablesCostSections = [
  {
    propertyName: 'diggingOutTheCableCosts',
    label: subCostLabels.diggingOutTheCableCosts
  },
  {
    propertyName: 'reinstatingCableTrenchCosts',
    label: subCostLabels.reinstatingCableTrenchCosts
  },
  {
    propertyName: 'transportCableToRecyclingCentreCosts',
    label: subCostLabels.transportCableToRecyclingCentreCosts
  },
  {
    propertyName: 'demobilisationPersonalPlusEquipmentCosts',
    label: subCostLabels.demobilisationPersonalPlusEquipmentCosts
  }
]
export const onshoreOtherOnSiteRemovalCostSections = [
  {
    propertyName: 'metMasts',
    label: subCostLabels.metMasts
  },
  {
    propertyName: 'hvSubstations',
    label: subCostLabels.hvSubstations
  },
  {
    propertyName: 'otherOnSiteBuildings',
    label: subCostLabels.otherOnSiteBuildings
  }
]
export const onshoreAccessRoadRemovalCostSections = [
  {
    propertyName: 'rippingRoads',
    label: subCostLabels.rippingRoads
  },
  {
    propertyName: 'roadRemovalOnSite',
    label: subCostLabels.roadRemovalOnSite
  },
  {
    propertyName: 'roadRemovalToAggregateRecyclingCentre',
    label: subCostLabels.roadRemovalToAggregateRecyclingCentre
  },
  {
    propertyName: 'removingGeotextileUnderneathRoad',
    label: subCostLabels.removingGeotextileUnderneathRoad
  },
  {
    propertyName: 'deMobilisationCostsPersonalEquipment',
    label: subCostLabels.deMobilisationCostsPersonalEquipment
  }
]
export const onshoreCraneRemovalCostSections = [
  {
    propertyName: 'rippingCranePads',
    label: subCostLabels.rippingCranePads
  },
  {
    propertyName: 'aggregateRemovalOnSite',
    label: subCostLabels.aggregateRemovalOnSite
  },
  {
    propertyName: 'aggregateRemovalToAggregateRecyclingCentre',
    label: subCostLabels.aggregateRemovalToAggregateRecyclingCentre
  },
  {
    propertyName: 'removingGeotextileUnderneathCranePad',
    label: subCostLabels.removingGeotextileUnderneathCranePad
  },
  {
    propertyName: 'deMobilisationCostsPersonalEquipment',
    label: subCostLabels.deMobilisationCostsPersonalEquipment
  }
]
export const onshoreReinstateCostSections = [
  {
    propertyName: 'importOfSubAndTopSoil',
    label: subCostLabels.importOfSubAndTopSoil
  },
  {
    propertyName: 'distributionOfTopSoilToRequiredAreas',
    label: subCostLabels.distributionOfTopSoilToRequiredAreas
  },
  {
    propertyName: 'dozeringAndRollingTopSoil',
    label: subCostLabels.dozeringAndRollingTopSoil
  },
  {
    propertyName: 'demobilisationCostsPersonalEquipment',
    label: subCostLabels.demobilisationCostsPersonalEquipment
  }
]
export const onshoreOverheadCostSections = [
  {
    propertyName: 'contractorCosts',
    label: subCostLabels.contractorCosts
  },
  {
    propertyName: 'ownersCosts',
    label: subCostLabels.ownersCosts
  }
]
export const onshoreScrapCostSections = [
  {
    propertyName: 'steelInTurbineTowers',
    label: subCostLabels.steelInTurbineTowers
  },
  {
    propertyName: 'steelInTurbineNacelles',
    label: subCostLabels.steelInTurbineNacelles
  },
  {
    propertyName: 'copperInGenerators',
    label: subCostLabels.copperInGenerators
  },
  {
    propertyName: 'copperInTransformers',
    label: subCostLabels.copperInTransformers
  },
  {
    propertyName: 'copperInSubstationsTransformers',
    label: subCostLabels.copperInSubstationsTransformers
  },
  {
    propertyName: 'aluminiumInCables',
    label: subCostLabels.aluminiumInCables
  }
]

export const scrapValues = [
  {
    propertyName: 'metalScrapValue',
    label: 'Metal scrap value'
  }
]

export const offshoreFloatingEngineeringCostSections = [
  {
    title: 'Removing turbine unit, transportation to port, disassembly',
    section: 'removingTurbineUnitTransportationToPortDissasembly'
  },
  {
    title: 'Removal of array cables',
    section: 'removalOfArrayCables'
  },
  {
    title: 'Mooring system and anchor removal',
    section: 'mooringSystemAndAnchorRemoval'
  },
  {
    title: 'Removal of export cables',
    section: 'removalOfExportCable'
  },
  {
    title: 'Removal of offshore substation',
    section: 'removalOfOffshoreSubstation'
  }
]

export const offshoreFixedEngineeringCostSections = [
  {
    title: 'Dismantling and transportation of offshore wind turbines',
    section: 'dismantlingAndTransportationOfOffshoreWindTurbines'
  },
  {
    title: 'Removal of array cables',
    section: 'removalOfArrayCables'
  },
  {
    title: 'Preparation for removal of foundations',
    section: 'preparationForRemovalOfFoundations'
  },
  {
    title: 'Removal of foundations',
    section: 'removalOfFoundations'
  },
  {
    title: 'Removal of export cables',
    section: 'removalOfExportCable'
  },
  {
    title: 'Removal of offshore substation',
    section: 'removalOfOffshoreSubstation'
  }
]

export const offshoreFloatingOverheadValues = [
  {
    title: 'Sea fastening (for OSS)',
    key: 'seaFasteningForOSS'
  },
  {
    title: 'Pre-decommissioning surveys',
    key: 'preDecommissioningSurveys'
  },
  {
    title: 'Post-decommissioning surveys',
    key: 'postDecommissioningSurveys'
  },
  {
    title: 'Project management',
    key: 'projectManagement'
  },
  {
    title: 'Harbour costs',
    key: 'harbourCosts'
  },
  {
    title: 'Back-office resources',
    key: 'backOfficeResources'
  },
  {
    title: 'Personnel transfer and safety vessels',
    key: 'personnelTransferAndSafetyVessels'
  }
]

export const offshoreFixedOverheadValues = [
  {
    title: 'Pre-decommissioning surveys',
    key: 'preDecommissioningSurveys'
  },
  {
    title: 'Post-decommissioning surveys',
    key: 'postDecommissioningSurveys'
  },
  {
    title: 'Project management',
    key: 'projectManagement'
  },
  {
    title: 'Harbour costs',
    key: 'harbourCosts'
  },
  {
    title: 'Back-office resources',
    key: 'backOfficeResources'
  },
  {
    title: 'Personnel transfer and safety vessels',
    key: 'personnelTransferAndSafetyVessels'
  },
  {
    title: 'Tools and jigs',
    key: 'toolsAndJigs'
  }
]
