import * as React from 'react'
import IconButton from '@mui/joy/IconButton'
import Table from '@mui/joy/Table'
import Typography from '@mui/joy/Typography'
import Sheet from '@mui/joy/Sheet'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box } from '@mui/joy'
import {
  CostViewModel,
  CurrencyInformationViewModel,
  OnshoreAccessRoadsRemovalCostsViewModel,
  OnshoreBladesDisposalCostsViewModel,
  OnshoreCableRemovalCostsViewModel,
  OnshoreCostCalculationViewModel,
  OnshoreCranePadsRemovalCostsViewModel,
  OnshoreDisassemblyCostsViewModel,
  OnshoreFoundationsDisposalCostsViewModel,
  OnshoreMaterialDisposalCostsViewModel,
  OnshoreProjectOverheadsCostsViewModel,
  OnshoreReinstateCostsViewModel,
  OnshoreRemovalOfOtherOnSiteConstructionsCostsViewModel,
  OnshoreRemovalOfTurbinesCostsViewModel,
  ScenarioItemViewModel
} from '@/interfaces'
import { costLabels, subCostLabels, costTooltips } from '@/utils/costNames'
import { Indicator, TooltipHelpText } from '@/components/helpers/index'
import { config, helper } from '@/utils'

interface Props {
  items?: OnshoreCostCalculationViewModel & { [key: string]: any }
  scenario?: ScenarioItemViewModel | null
  valueFormater?: Function
  scenarioCurrency?: CurrencyInformationViewModel | null
  forceOpen?: boolean
  simpleTable?: boolean
  blockExpand?: boolean
  headers?: string[]
  isTotal?: boolean
}
const { calculations } = config
const { verifiedElement } = helper

function Row(props: {
  row:
    | (
        | OnshoreDisassemblyCostsViewModel
        | OnshoreRemovalOfTurbinesCostsViewModel
        | OnshoreMaterialDisposalCostsViewModel
        | OnshoreBladesDisposalCostsViewModel
        | OnshoreFoundationsDisposalCostsViewModel
        | OnshoreCableRemovalCostsViewModel
        | OnshoreRemovalOfOtherOnSiteConstructionsCostsViewModel
        | OnshoreAccessRoadsRemovalCostsViewModel
        | OnshoreCranePadsRemovalCostsViewModel
        | OnshoreReinstateCostsViewModel
        | OnshoreProjectOverheadsCostsViewModel
      )
    | CostViewModel
  isExpandable?: boolean
  name: string
  valueFormater?: Function
  scenarioCurrency?: CurrencyInformationViewModel | null
  forceOpen?: boolean
  simpleTable?: boolean
  isTotal?: boolean
}) {
  const {
    row,
    name,
    isExpandable,
    scenarioCurrency,
    valueFormater,
    forceOpen = false,
    simpleTable = false,
    isTotal = false
  } = props
  const [open, setOpen] = React.useState(forceOpen)
  const hasSubcosts =
    !!row &&
    Object.keys(row).filter(
      key => key !== 'absolute' && key !== 'perMW' && key !== 'perTurbine'
    ).length > 1

  const valueHandler = (value?: any) => {
    if (typeof value === 'string') return value

    return !!valueFormater
      ? valueFormater?.(value, scenarioCurrency)
      : verifiedElement(value)
  }

  return (
    <React.Fragment>
      <tr
        style={{
          width: '100%',
          textAlign: 'right',
          backgroundColor: 'white'
        }}>
        <td
          scope="row"
          style={{
            width: '66%',
            backgroundColor: 'white',
            maxHeight: '40px',
            borderTop: !forceOpen && isTotal ? '2px solid black' : 'none',
            borderBottom: isTotal ? 'none' : ''
          }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: isTotal ? 500 : 400
              }}>
              <Indicator
                size={10}
                colorCode={
                  calculations.chartColors?.[
                    name as keyof typeof calculations.chartColors
                  ] ?? '#99D9F0'
                }
                className="mr-2"
              />
              <Typography level="body-md">
                {costLabels[name] ?? name}
              </Typography>
            </Box>
            {simpleTable && costTooltips[name] && (
              <TooltipHelpText message={costTooltips[name]} />
            )}
          </Box>
        </td>
        <td
          style={{
            maxHeight: '40px',
            padding: '0 8px',
            backgroundColor: 'white',
            borderTop: !forceOpen && isTotal ? '2px solid black' : 'none',
            borderBottom: isTotal ? 'none' : ''
          }}>
          <Box
            justifyContent="space-between"
            alignItems="center"
            display="flex"
            sx={{
              display: 'flex',
              backgroundColor: 'white'
            }}>
            <Typography level="body-md">
              {valueHandler(
                name === 'metalScrapValues'
                  ? // @ts-ignore
                    -(row?.totalCosts?.absolute ?? row?.absolute ?? 0)
                  : // @ts-ignore
                    row?.totalCosts?.absolute ?? row?.absolute ?? 0
              )}
            </Typography>
            {isExpandable && hasSubcosts && !forceOpen && (
              <IconButton
                aria-label="expand row"
                variant="plain"
                size="sm"
                sx={{ maxHeight: '20px' }}
                onClick={() => setOpen(!open)}>
                {open ? (
                  <KeyboardArrowUpIcon sx={{ color: '#636B74' }} />
                ) : (
                  <KeyboardArrowDownIcon sx={{ color: '#636B74' }} />
                )}
              </IconButton>
            )}
          </Box>
        </td>
        {!simpleTable && (
          <>
            <td
              style={{
                borderTop: !forceOpen && isTotal ? '2px solid black' : 'none',
                borderBottom: isTotal ? 'none' : '',
                backgroundColor: 'white'
              }}>
              <Typography sx={{ textAlign: 'left' }} level="body-md">
                {valueHandler(
                  name === 'metalScrapValues'
                    ? // @ts-ignore
                      -(row?.totalCosts?.perTurbine ?? row?.perTurbine ?? 0)
                    : // @ts-ignore
                      row?.totalCosts?.perTurbine ?? row?.perTurbine ?? 0
                )}
              </Typography>
            </td>
            <td
              style={{
                borderTop: !forceOpen && isTotal ? '2px solid black' : 'none',
                borderBottom: isTotal ? 'none' : '',
                backgroundColor: 'white'
              }}>
              <Typography sx={{ textAlign: 'left' }} level="body-md">
                {valueHandler(
                  name === 'metalScrapValues'
                    ? // @ts-ignore
                      -(row?.totalCosts?.perMW ?? row?.perMW ?? 0)
                    : // @ts-ignore
                      row?.totalCosts?.perMW ?? row?.perMW ?? 0
                )}
              </Typography>
            </td>
          </>
        )}
      </tr>
      <tr>
        <td style={{ height: 0, padding: 0 }} colSpan={simpleTable ? 2 : 4}>
          {open && hasSubcosts && isExpandable && (
            <Table size="md">
              <tbody>
                {Object.keys(row)
                  .filter(cost => cost !== 'totalCosts')
                  .map(r => {
                    const value = row[r as keyof typeof row]
                    // @ts-ignore
                    // @ts-ignore
                    return (
                      <tr key={r}>
                        <td
                          scope="row"
                          style={{
                            width: '65.9%',
                            borderLeft: 'none',
                            backgroundColor: '#F0F4F8'
                          }}>
                          <Typography pl={6} level="body-md">
                            {subCostLabels[r] ?? r}
                          </Typography>
                        </td>
                        <td
                          scope="row"
                          style={{
                            borderRight: 'none',
                            backgroundColor: '#F0F4F8'
                          }}>
                          <Typography level="body-md">
                            {/*@ts-ignore*/}
                            {value && valueHandler(value.absolute)}
                          </Typography>
                        </td>
                        {!simpleTable && (
                          <td
                            scope="row"
                            style={{
                              borderRight: 'none',
                              backgroundColor: '#F0F4F8'
                            }}>
                            <Typography level="body-md">
                              {/*@ts-ignore*/}
                              {value && valueHandler(value.perTurbine)}
                            </Typography>
                          </td>
                        )}
                        {!simpleTable && (
                          <td
                            scope="row"
                            style={{
                              borderRight: 'none',
                              backgroundColor: '#F0F4F8'
                            }}>
                            <Typography level="body-md">
                              {/*@ts-ignore*/}
                              {value && valueHandler(value.perMW)}
                            </Typography>
                          </td>
                        )}
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
          )}
        </td>
      </tr>
    </React.Fragment>
  )
}

const ReReportTable = ({
  items,
  valueFormater,
  scenario,
  scenarioCurrency,
  simpleTable = false,
  forceOpen,
  headers,
  blockExpand = false
}: Props) => {
  const filteredKeys =
    items &&
    Object.keys(items).filter(key => key !== 'updatedAt' && key !== 'isManual')
  const keysWithScrapIncluded = scenario?.includeScrapValue
    ? filteredKeys?.filter(
        key => key !== 'totalCosts' && key !== 'netTotalCosts'
      )
    : filteredKeys?.filter(
        key =>
          key !== 'metalScrapValues' &&
          key !== 'netTotalCosts' &&
          key !== 'totalCosts'
      )

  const keysWithContingencyIncluded = scenario?.includeContingencyAllocation
    ? keysWithScrapIncluded
    : keysWithScrapIncluded?.filter(key => key !== 'contingencyAllocation')

  const totalCosts = scenario?.includeScrapValue
    ? items?.netTotalCosts
    : items?.totalCosts

  return (
    <Sheet variant={simpleTable ? 'outlined' : 'plain'}>
      <Table
        borderAxis="both"
        sx={{
          width: blockExpand ? 'calc(100% - 30px)' : '100%',
          margin: blockExpand ? '0 15px' : ''
        }}>
        {!!headers && (
          <thead>
            <tr>
              {headers?.map((header, index) => (
                <td
                  key={index}
                  style={{ width: index === 0 ? '66%' : 'auto' }}
                  className={['border', 'bg-light'].join(' ')}>
                  <Typography level="body-md" sx={{ fontWeight: 500 }}>
                    {header}
                  </Typography>
                </td>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {keysWithContingencyIncluded &&
            keysWithContingencyIncluded.map((key: string) => {
              if (items) {
                const value = items[key]
                return (
                  <Row
                    key={key}
                    row={value}
                    name={key}
                    isExpandable={
                      key !== 'netTotalCosts' &&
                      key !== 'totalCosts' &&
                      key !== 'contingencyAllocation' &&
                      !blockExpand
                    }
                    valueFormater={valueFormater}
                    scenarioCurrency={scenarioCurrency}
                    forceOpen={forceOpen}
                    simpleTable={simpleTable}
                  />
                )
              }
            })}
        </tbody>
        <tfoot>
          {totalCosts && (
            <Row
              row={totalCosts as CostViewModel}
              name={
                scenario?.includeScrapValue ? 'netTotalCosts' : 'totalCosts'
              }
              isExpandable={false}
              valueFormater={valueFormater}
              scenarioCurrency={scenarioCurrency}
              forceOpen={forceOpen}
              simpleTable={simpleTable}
              isTotal
            />
          )}
        </tfoot>
      </Table>
    </Sheet>
  )
}

export default ReReportTable
