import { ProjectType, ReportSectionCode } from '@/interfaces'
import useLocalStorage from './useLocalStorage'
import { useEffect, useState } from 'react'

const onshoreSections = [
  ReportSectionCode.ExecutiveSummary,
  ReportSectionCode.Introduction,
  ReportSectionCode.MaterialComposition,
  ReportSectionCode.BalanceOfPlant,
  ReportSectionCode.Circularity,
  ReportSectionCode.SupplyChainMap,
  ReportSectionCode.DecommissioningCosts,
  ReportSectionCode.Appendix
]
const offshoreSections = [
  ReportSectionCode.ExecutiveSummary,
  ReportSectionCode.Introduction,
  ReportSectionCode.MaterialComposition,
  ReportSectionCode.BalanceOfPlant,
  ReportSectionCode.Circularity,
  ReportSectionCode.SupplyChainMap,
  ReportSectionCode.DecommissioningCosts
]

const sectionNames = {
  [ReportSectionCode.ExecutiveSummary]: 'Executive Summary',
  [ReportSectionCode.Introduction]: 'Introduction',
  [ReportSectionCode.MaterialComposition]: 'Material composition',
  [ReportSectionCode.BalanceOfPlant]: 'Balance of plant',
  [ReportSectionCode.Circularity]: 'Circularity',
  [ReportSectionCode.SupplyChainMap]: 'Supply chain map',
  [ReportSectionCode.DecommissioningCosts]: 'Decommissioning costs',
  [ReportSectionCode.Appendix]: 'Appendix'
}

const useReportPrintSections = (
  scenarioId?: string,
  projectType?: ProjectType
) => {
  const allSections =
    projectType === ProjectType.OffshoreBottomFixed ||
    projectType === ProjectType.OffshoreFloating
      ? offshoreSections
      : onshoreSections
  const [printSections, setPrintSections] = useLocalStorage<number[]>(
    `useReportPrintSections-${scenarioId}`,
    allSections
  )
  const [isPdfReady, setIsPdfReady] = useState<boolean>(false)
  const [sections, setSections] = useState<any>(sectionNames)

  useEffect(() => {
    setSections(
      Object.fromEntries(
        allSections.map((section, index) => [index, sectionNames[section]])
      )
    )
  }, [projectType])

  useEffect(() => {
    setIsPdfReady(false)
    const delay = () =>
      setTimeout(() => {
        setIsPdfReady(true)
      }, 3000)
    clearTimeout(delay())

    delay()
  }, [printSections])

  const allSelected =
    !!printSections && printSections?.length >= allSections?.length
  const clearAll = () => {
    setPrintSections([])
  }
  const toggle = (input: string) => {
    const section = parseInt(input)

    const sections = printSections?.length ? printSections : []
    const value = sections?.includes(section)
      ? sections.filter(i => i !== section)
      : [...sections, section]

    setPrintSections(value)
  }

  const selectAll = () => {
    setPrintSections(allSections)
  }

  return {
    allSelected,
    sectionNames: sections,
    printSections,
    setPrintSections,
    clearAll,
    toggle,
    selectAll,
    isPdfReady
  }
}
export default useReportPrintSections
