import React from 'react'
import Page from '../../layout/Page'
import ContentSection from '../../layout/ContentSection'
import { OTable } from '@dnvgl-onefoundation/onedesign-react'
import { useTexts } from '@/hooks'

const PrivacyPage = () => {
  const texts = useTexts()
  return (
    <Page title={texts?.cookies.title} isFullWidthTitle>
      <ContentSection className="pt-4 pb-5">
        <p>
          The{' '}
          <a
            href="https://id.veracity.com/terms-of-use"
            target="_blank"
            rel="noreferrer">
            Veracity platform cookies policy
          </a>{' '}
          defines how we use cookies.{' '}
        </p>{' '}
        <p>{texts?.cookies.intro}</p>
        <OTable
          small
          showOptionsButton={false}
          showFilterButton={false}
          showColumnsButton={false}
          fields={[
            { name: 'url', heading: 'Domain' },
            { name: 'cookie', heading: 'Name' },
            { name: 'use', heading: 'Set and/or retrieved by' },
            { name: 'lifespan', heading: 'Lifespan' }
          ]}
          allItems={[
            {
              url: 'rewind.dnv.com',
              name: '.AspNetCore.Cookies',
              use: '1st Party',
              lifespan: 'Session'
            },
            {
              url: 'rewind.dnv.com',
              name: '.AspNetCore.Antiforgery',
              use: '1st Party',
              lifespan: 'Session'
            },
            {
              url: 'rewind.dnv.com',
              name: 'RequestVerificationToken',
              use: '1st Party',
              lifespan: 'Session'
            },
            {
              url: 'rewind.dnv.com',
              name: 'ARRAffinity',
              use: '1st Party',
              lifespan: 'Session'
            },
            {
              url: 'rewind.dnv.com',
              name: 'ARRAffinitySameSite',
              use: '1st Party',
              lifespan: 'Session'
            },
            {
              url: 'rewind.dnv.com',
              name: 'ai_session',
              use: '1st Party',
              lifespan: '30 minutes'
            },
            {
              url: 'rewind.dnv.com',
              name: 'ai_user',
              use: '1st Party',
              lifespan: '1 year'
            }
          ].map(x => ({
            ...x,
            cookie: (
              <a
                href={`https://cookiepedia.co.uk/cookies/${x.name}`}
                target="_blank"
                rel="noreferrer">
                {x.name}
              </a>
            )
          }))}
        />
        <p>{texts?.cookies.outro}</p>
      </ContentSection>
    </Page>
  )
}

export default React.memo(PrivacyPage)
