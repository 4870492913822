import {
  CostValueOption,
  OffshoreFixedCostCalculationViewModel,
  OffshoreFloatingCostCalculationViewModel,
  OnshoreCostCalculationViewModel,
  UpdateOffshoreFixedCostCalculationViewModel,
  UpdateOffshoreFloatingCostCalculationViewModel,
  UpdateOnshoreCostCalculationViewModel
} from '@/interfaces'

const getValue = (
  results: any | null,
  type: any,
  option: CostValueOption = 'value'
) => {
  if (results === null) return undefined
  const item = results?.costs?.find?.((i: any) => i.type === type)

  if (!item) return undefined

  return item[option]
}

const transformOnshore = (
  calculation?: OnshoreCostCalculationViewModel | null
): UpdateOnshoreCostCalculationViewModel => {
  return {
    disassemblyCosts: {
      disassemblyCostsOfRotors:
        calculation?.disassemblyCosts.disassemblyCostsOfRotors.absolute || 0,
      disassemblyCostsOfNacelleAndHubs:
        calculation?.disassemblyCosts.disassemblyCostsOfNacelleAndHubs
          .absolute || 0,
      disassemblyCostsOfTowers:
        calculation?.disassemblyCosts.disassemblyCostsOfTowers.absolute || 0,
      disassemblyCostsOfTransformers:
        calculation?.disassemblyCosts.disassemblyCostsOfTransformers.absolute ||
        0,
      mobilisationDemobilisationOfCranePersonalOtherEquipmentCosts:
        calculation?.disassemblyCosts
          .mobilisationDemobilisationOfCranePersonalOtherEquipmentCosts
          .absolute || 0
    },
    removalOfTurbinesCosts: {
      loadingCostsTurbineTowers:
        calculation?.removalOfTurbinesCosts.loadingCostsTurbineTowers
          .absolute || 0,
      transportCostOfTurbineTowers:
        calculation?.removalOfTurbinesCosts.transportCostOfTurbineTowers
          .absolute || 0,
      loadingCostsTurbineBlades:
        calculation?.removalOfTurbinesCosts.loadingCostsTurbineBlades
          .absolute || 0,
      transportCostOfTurbineBlades:
        calculation?.removalOfTurbinesCosts.transportCostOfTurbineBlades
          .absolute || 0,
      loadingCostsOfRemainingNacelleAndGen:
        calculation?.removalOfTurbinesCosts.loadingCostsOfRemainingNacelleAndGen
          .absolute || 0,
      transportCostsOfRemainingNacelleAndGen:
        calculation?.removalOfTurbinesCosts
          .transportCostsOfRemainingNacelleAndGen.absolute || 0,
      demobilisationCostsPersonalAndEquipment:
        calculation?.removalOfTurbinesCosts
          .demobilisationCostsPersonalAndEquipment.absolute || 0
    },
    materialDisposalCosts: {
      costOfRecyclingAllFoundations:
        calculation?.materialDisposalCosts.costOfRecyclingAllFoundations
          .absolute || 0,
      costForLooseAggregateFromRoads:
        calculation?.materialDisposalCosts.costForLooseAggregateFromRoads
          .absolute || 0,
      costForLooseAggregateFromCranePads:
        calculation?.materialDisposalCosts.costForLooseAggregateFromCranePads
          .absolute || 0
    },
    bladesDisposalCosts: {
      total: calculation?.bladesDisposalCosts.totalCosts.absolute || 0
    },
    foundationsDisposalCosts: {
      breakOutCuttingReBarsRemovalOnSite:
        calculation?.foundationsDisposalCosts.breakOutCuttingReBarsRemovalOnSite
          .absolute || 0,
      loadingAggregateOntoTipperTrucks:
        calculation?.foundationsDisposalCosts.loadingAggregateOntoTipperTrucks
          .absolute || 0,
      transportAggregateToRecyclingCentre:
        calculation?.foundationsDisposalCosts
          .transportAggregateToRecyclingCentre.absolute || 0,
      demobilisationCostsPersonalEquipment:
        calculation?.foundationsDisposalCosts
          .demobilisationCostsPersonalEquipment.absolute || 0
    },
    cableRemovalCosts: {
      diggingOutTheCableCosts:
        calculation?.cableRemovalCosts.diggingOutTheCableCosts.absolute || 0,
      reinstatingCableTrenchCosts:
        calculation?.cableRemovalCosts.reinstatingCableTrenchCosts.absolute ||
        0,
      transportCableToRecyclingCentreCosts:
        calculation?.cableRemovalCosts.transportCableToRecyclingCentreCosts
          .absolute || 0,
      demobilisationPersonalPlusEquipmentCosts:
        calculation?.cableRemovalCosts.demobilisationPersonalPlusEquipmentCosts
          .absolute || 0
    },
    removalOfOtherOnSiteConstructionsCosts: {
      metMasts:
        calculation?.removalOfOtherOnSiteConstructions.metMasts.absolute || 0,
      hvSubstations:
        calculation?.removalOfOtherOnSiteConstructions.hvSubstations.absolute ||
        0,
      otherOnSiteBuildings:
        calculation?.removalOfOtherOnSiteConstructions.otherOnSiteBuildings
          .absolute || 0
    },
    accessRoadsRemovalCosts: {
      rippingRoads:
        calculation?.accessRoadsRemovalCosts.rippingRoads.absolute || 0,
      roadRemovalOnSite:
        calculation?.accessRoadsRemovalCosts.roadRemovalOnSite.absolute || 0,
      roadRemovalToAggregateRecyclingCentre:
        calculation?.accessRoadsRemovalCosts
          .roadRemovalToAggregateRecyclingCentre.absolute || 0,
      removingGeotextileUnderneathRoad:
        calculation?.accessRoadsRemovalCosts.removingGeotextileUnderneathRoad
          .absolute || 0,
      deMobilisationCostsPersonalEquipment:
        calculation?.accessRoadsRemovalCosts
          .deMobilisationCostsPersonalEquipment.absolute || 0
    },
    cranePadsRemovalCosts: {
      rippingCranePads:
        calculation?.cranePadsRemovalCosts.rippingCranePads.absolute || 0,
      aggregateRemovalOnSite:
        calculation?.cranePadsRemovalCosts.aggregateRemovalOnSite.absolute || 0,
      aggregateRemovalToAggregateRecyclingCentre:
        calculation?.cranePadsRemovalCosts
          .aggregateRemovalToAggregateRecyclingCentre.absolute || 0,
      removingGeotextileUnderneathCranePad:
        calculation?.cranePadsRemovalCosts.removingGeotextileUnderneathCranePad
          .absolute || 0,
      deMobilisationCostsPersonalEquipment:
        calculation?.cranePadsRemovalCosts.deMobilisationCostsPersonalEquipment
          .absolute || 0
    },
    reinstateCosts: {
      importOfSubAndTopSoil:
        calculation?.reinstateCosts.importOfSubAndTopSoil.absolute || 0,
      distributionOfTopSoilToRequiredAreas:
        calculation?.reinstateCosts.distributionOfTopSoilToRequiredAreas
          .absolute || 0,
      dozeringAndRollingTopSoil:
        calculation?.reinstateCosts.dozeringAndRollingTopSoil.absolute || 0,
      demobilisationCostsPersonalEquipment:
        calculation?.reinstateCosts.demobilisationCostsPersonalEquipment
          .absolute || 0
    },
    projectOverheadsCosts: {
      contractorCosts:
        calculation?.onshoreProjectOverheadCosts.contractorCosts.absolute || 0,
      ownersCosts:
        calculation?.onshoreProjectOverheadCosts.ownersCosts.absolute || 0
    },
    metalScrapValues: {
      steelInTurbineTowers:
        calculation?.metalScrapValues.steelInTurbineTowers.absolute || 0,
      steelInTurbineNacelles:
        calculation?.metalScrapValues.steelInTurbineNacelles.absolute || 0,
      copperInGenerators:
        calculation?.metalScrapValues.copperInGenerators.absolute || 0,
      copperInTransformers:
        calculation?.metalScrapValues.copperInTransformers.absolute || 0,
      copperInSubstationsTransformers:
        calculation?.metalScrapValues.copperInSubstationsTransformers
          .absolute || 0,
      aluminiumInCables:
        calculation?.metalScrapValues.aluminiumInCables.absolute || 0
    }
  }
}

const transformOffshoreFixed = (
  calculation?: OffshoreFixedCostCalculationViewModel | null
): UpdateOffshoreFixedCostCalculationViewModel => ({
  removalOfArrayCables: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.removalOfArrayCables.offshoreCostViewModel.mobilisation
          .absolute || 0,
      demobilisation:
        calculation?.removalOfArrayCables.offshoreCostViewModel.demobilisation
          .absolute || 0,
      operatingDuration:
        calculation?.removalOfArrayCables.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.removalOfArrayCables?.dayRate?.vesselAndStaff?.absolute || 0,
    fuel: calculation?.removalOfArrayCables?.dayRate?.fuel?.absolute || 0,
    crew: calculation?.removalOfArrayCables?.dayRate?.crew?.absolute || 0,
    equipment:
      calculation?.removalOfArrayCables?.dayRate?.equipment?.absolute || 0,
    deBurial:
      calculation?.removalOfArrayCables?.dayRate?.deBurial?.absolute || 0
  },
  removalOfOffshoreSubstation: {
    updateOffshoreFixedCostViewModel: {
      mobilisation:
        calculation?.removalOfOffshoreSubstation.offshoreCostViewModel
          .mobilisation.absolute || 0,
      demobilisation:
        calculation?.removalOfOffshoreSubstation.offshoreCostViewModel
          .demobilisation.absolute || 0,
      operatingDuration:
        calculation?.removalOfOffshoreSubstation.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.removalOfOffshoreSubstation?.dayRate?.vesselAndStaff
        ?.absolute || 0,
    fuel:
      calculation?.removalOfOffshoreSubstation?.dayRate?.fuel?.absolute || 0,
    bargeTugSetup:
      calculation?.removalOfOffshoreSubstation?.dayRate?.bargeTugSetup
        ?.absolute || 0
  },
  removalOfExportCable: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.removalOfExportCable.offshoreCostViewModel.mobilisation
          .absolute || 0,
      demobilisation:
        calculation?.removalOfExportCable.offshoreCostViewModel.demobilisation
          .absolute || 0,
      operatingDuration:
        calculation?.removalOfExportCable.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.removalOfExportCable?.dayRate?.vesselAndStaff?.absolute || 0,
    fuel: calculation?.removalOfExportCable?.dayRate?.fuel?.absolute || 0,
    crew: calculation?.removalOfExportCable?.dayRate?.crew?.absolute || 0,
    equipment:
      calculation?.removalOfExportCable?.dayRate?.equipment?.absolute || 0,
    deBurial:
      calculation?.removalOfExportCable?.dayRate?.deBurial?.absolute || 0
  },
  dismantlingAndTransportationOfOffshoreWindTurbines: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.dismantlingAndTransportationOfOffshoreWindTurbines
          .offshoreCostViewModel.mobilisation.absolute || 0,
      demobilisation:
        calculation?.dismantlingAndTransportationOfOffshoreWindTurbines
          .offshoreCostViewModel.demobilisation.absolute || 0,
      operatingDuration:
        calculation?.dismantlingAndTransportationOfOffshoreWindTurbines
          .offshoreCostViewModel.operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.dismantlingAndTransportationOfOffshoreWindTurbines?.dayRate
        ?.vesselAndStaff?.absolute || 0,
    fuel:
      calculation?.dismantlingAndTransportationOfOffshoreWindTurbines?.dayRate
        ?.fuel?.absolute || 0,
    crew:
      calculation?.dismantlingAndTransportationOfOffshoreWindTurbines?.dayRate
        ?.crew?.absolute || 0,
    equipment:
      calculation?.dismantlingAndTransportationOfOffshoreWindTurbines?.dayRate
        ?.equipment?.absolute || 0
  },
  preparationForRemovalOfFoundations: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.preparationForRemovalOfFoundations.offshoreCostViewModel
          .mobilisation.absolute || 0,
      demobilisation:
        calculation?.preparationForRemovalOfFoundations.offshoreCostViewModel
          .demobilisation.absolute || 0,
      operatingDuration:
        calculation?.preparationForRemovalOfFoundations.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.preparationForRemovalOfFoundations?.dayRate?.vesselAndStaff
        ?.absolute || 0,
    fuel:
      calculation?.preparationForRemovalOfFoundations?.dayRate?.fuel
        ?.absolute || 0,
    crew:
      calculation?.preparationForRemovalOfFoundations?.dayRate?.crew
        ?.absolute || 0,
    equipment:
      calculation?.preparationForRemovalOfFoundations?.dayRate?.equipment
        ?.absolute || 0
  },
  removalOfFoundations: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.removalOfFoundations.offshoreCostViewModel.mobilisation
          .absolute || 0,
      demobilisation:
        calculation?.removalOfFoundations.offshoreCostViewModel.demobilisation
          .absolute || 0,
      operatingDuration:
        calculation?.removalOfFoundations.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.removalOfFoundations?.dayRate?.vesselAndStaff?.absolute || 0,
    fuel: calculation?.removalOfFoundations?.dayRate?.fuel?.absolute || 0,
    crew: calculation?.removalOfFoundations?.dayRate?.crew?.absolute || 0,
    equipment:
      calculation?.removalOfFoundations?.dayRate?.equipment?.absolute || 0
  },
  preDecommissioningSurveys:
    calculation?.preDecommissioningSurveys?.absolute || 0,
  postDecommissioningSurveys:
    calculation?.postDecommissioningSurveys?.absolute || 0,
  projectManagement: calculation?.projectManagement?.absolute || 0,
  harbourCosts: calculation?.harbourCosts?.absolute || 0,
  backOfficeResources: calculation?.backOfficeResources?.absolute || 0,
  personnelTransferAndSafetyVessels:
    calculation?.personnelTransferAndSafetyVessels?.absolute || 0,
  toolsAndJigs: calculation?.toolsAndJigs?.absolute || 0,
  metalScrapValue: calculation?.metalScrapValue?.absolute || 0
})

const transformOffshoreFloating = (
  calculation?: OffshoreFloatingCostCalculationViewModel | null
): UpdateOffshoreFloatingCostCalculationViewModel => ({
  removalOfArrayCables: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.removalOfArrayCables.offshoreCostViewModel.mobilisation
          .absolute || 0,
      demobilisation:
        calculation?.removalOfArrayCables.offshoreCostViewModel.demobilisation
          .absolute || 0,
      operatingDuration:
        calculation?.removalOfArrayCables.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.removalOfArrayCables?.dayRate?.vesselAndStaff?.absolute || 0,
    fuel: calculation?.removalOfArrayCables?.dayRate?.fuel?.absolute || 0,
    crew: calculation?.removalOfArrayCables?.dayRate?.crew?.absolute || 0,
    equipment:
      calculation?.removalOfArrayCables?.dayRate?.equipment?.absolute || 0,
    deBurial:
      calculation?.removalOfArrayCables?.dayRate?.deBurial?.absolute || 0
  },
  removalOfOffshoreSubstation: {
    updateOffshoreFixedCostViewModel: {
      mobilisation:
        calculation?.removalOfOffshoreSubstation.offshoreCostViewModel
          .mobilisation.absolute || 0,
      demobilisation:
        calculation?.removalOfOffshoreSubstation.offshoreCostViewModel
          .demobilisation.absolute || 0,
      operatingDuration:
        calculation?.removalOfOffshoreSubstation.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.removalOfOffshoreSubstation?.dayRate?.vesselAndStaff
        ?.absolute || 0,
    fuel:
      calculation?.removalOfOffshoreSubstation?.dayRate?.fuel?.absolute || 0,
    bargeTugSetup:
      calculation?.removalOfOffshoreSubstation?.dayRate?.bargeTugSetup
        ?.absolute || 0
  },
  removalOfExportCable: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.removalOfExportCable.offshoreCostViewModel.mobilisation
          .absolute || 0,
      demobilisation:
        calculation?.removalOfExportCable.offshoreCostViewModel.demobilisation
          .absolute || 0,
      operatingDuration:
        calculation?.removalOfExportCable.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.removalOfExportCable?.dayRate?.vesselAndStaff?.absolute || 0,
    fuel: calculation?.removalOfExportCable?.dayRate?.fuel?.absolute || 0,
    crew: calculation?.removalOfExportCable?.dayRate?.crew?.absolute || 0,
    equipment:
      calculation?.removalOfExportCable?.dayRate?.equipment?.absolute || 0,
    deBurial:
      calculation?.removalOfExportCable?.dayRate?.deBurial?.absolute || 0
  },
  removingTurbineUnitTransportationToPortDissasembly: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.removingTurbineUnitTransportationToPortDissasembly
          .offshoreCostViewModel.mobilisation.absolute || 0,
      demobilisation:
        calculation?.removingTurbineUnitTransportationToPortDissasembly
          .offshoreCostViewModel.demobilisation.absolute || 0,
      operatingDuration:
        calculation?.removingTurbineUnitTransportationToPortDissasembly
          .offshoreCostViewModel.operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.removingTurbineUnitTransportationToPortDissasembly?.dayRate
        ?.vesselAndStaff?.absolute || 0,
    fuel:
      calculation?.removingTurbineUnitTransportationToPortDissasembly?.dayRate
        ?.fuel?.absolute || 0,
    crew:
      calculation?.removingTurbineUnitTransportationToPortDissasembly?.dayRate
        ?.crew?.absolute || 0,
    equipment:
      calculation?.removingTurbineUnitTransportationToPortDissasembly?.dayRate
        ?.equipment?.absolute || 0
  },
  mooringSystemAndAnchorRemoval: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.mooringSystemAndAnchorRemoval.offshoreCostViewModel
          .mobilisation.absolute || 0,
      demobilisation:
        calculation?.mooringSystemAndAnchorRemoval.offshoreCostViewModel
          .demobilisation.absolute || 0,
      operatingDuration:
        calculation?.mooringSystemAndAnchorRemoval.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.mooringSystemAndAnchorRemoval?.dayRate?.vesselAndStaff
        ?.absolute || 0,
    fuel:
      calculation?.mooringSystemAndAnchorRemoval?.dayRate?.fuel?.absolute || 0,
    crew:
      calculation?.mooringSystemAndAnchorRemoval?.dayRate?.crew?.absolute || 0,
    equipment:
      calculation?.mooringSystemAndAnchorRemoval?.dayRate?.equipment
        ?.absolute || 0
  },
  seaFasteningForOSS: calculation?.seaFasteningForOSS?.absolute || 0,
  preDecommissioningSurveys:
    calculation?.preDecommissioningSurveys?.absolute || 0,
  postDecommissioningSurveys:
    calculation?.postDecommissioningSurveys?.absolute || 0,
  projectManagement: calculation?.projectManagement?.absolute || 0,
  harbourCosts: calculation?.harbourCosts?.absolute || 0,
  backOfficeResources: calculation?.backOfficeResources?.absolute || 0,
  personnelTransferAndSafetyVessels:
    calculation?.personnelTransferAndSafetyVessels?.absolute || 0,
  metalScrapValue: calculation?.metalScrapValue?.absolute || 0
})

const costs = {
  getValue,
  transformOnshore,
  transformOffshoreFixed,
  transformOffshoreFloating
}

export default costs
