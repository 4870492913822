import React, { ReactNode } from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { helper } from '@/utils'
import {
  CurrencyInformationViewModel,
  OnshoreCostCalculationViewModel,
  ScenarioItemViewModel
} from '@/interfaces'
import { costLabels, subCostLabels } from '@/utils/costNames'

const styles = StyleSheet.create({
  section: {
    flexGrow: 1,
    fontSize: 9,
    marginBottom: 10
  },
  name: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    fontWeight: 'bold',
    fontSize: 9,
    padding: 3,
    backgroundColor: '#0f204b',
    color: '#fff'
  },
  table: {
    display: 'flex',
    flexDirection: 'column'
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  tableColHeader: {
    flex: 1,
    padding: 4,
    backgroundColor: '#f2f2f2',
    fontWeight: 'bold',
    borderStyle: 'solid',
    borderColor: '#dee2e6',
    borderWidth: 1,
    textAlign: 'center'
  },
  singleCellRow: {
    flexDirection: 'row',
    fontSize: 9,
    alignItems: 'center',
    backgroundColor: '#f8f9fa',
    fontWeight: 'bold',
    borderStyle: 'solid',
    borderColor: '#dee2e6'
  },
  singleCell: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#dee2e6',
    flex: 1,
    padding: 2,
    alignItems: 'stretch',
    height: '100%',
    textAlign: 'left'
  },
  singleCellInner: {
    borderStyle: 'solid',
    borderColor: '#dee2e6',
    backgroundColor: '#fff',
    borderWidth: 1,
    flex: 1,
    padding: 2,
    alignItems: 'stretch',
    textAlign: 'left'
  }
})

interface Props {
  linkId?: string
  name?: string | ReactNode
  description?: string
  headers?: string[]
  costs?: OnshoreCostCalculationViewModel & {
    [key: string]: any
  }
  valueFormater?: (
    value: number,
    currencyInformation: CurrencyInformationViewModel
  ) => string | number
  breakPage?: boolean
  currencyInformation?: CurrencyInformationViewModel
  scenario?: ScenarioItemViewModel
}

interface RowProps {
  style?: string
  category: string
  row?: any[] & {
    [key: string]: any
  }
  currencyInformation?: CurrencyInformationViewModel
  valueFormater?: (
    value: number,
    currencyInformation: CurrencyInformationViewModel
  ) => string | number
  isTotal?: boolean
}

interface CellProps {
  style?: string
  category: string
  currencyInformation?: CurrencyInformationViewModel
  items?: any[] & {
    [key: string]: any
  }
  valueFormater?: (
    value: number,
    currencyInformation: CurrencyInformationViewModel
  ) => string | number
}

const { verifiedElement } = helper

const valueHandler = (
  value?: any,
  valueFormater?: Function,
  currencyInformation?: any
) => {
  if (typeof value === 'string') return value
  return !!valueFormater
    ? valueFormater?.(value, currencyInformation)
    : verifiedElement(value)
}
const Row: React.FC<RowProps> = ({
  row,
  category,
  valueFormater,
  currencyInformation,
  isTotal
}) => {
  return (
    <View style={isTotal ? styles.singleCellRow : styles.tableRow}>
      <Text style={isTotal ? styles.singleCell : styles.singleCellInner}>
        {subCostLabels[category]}
      </Text>
      <Text style={isTotal ? styles.singleCell : styles.singleCellInner}>
        {valueHandler(row?.absolute, valueFormater, currencyInformation)}
      </Text>
      <Text style={isTotal ? styles.singleCell : styles.singleCellInner}>
        {valueHandler(row?.perTurbine, valueFormater, currencyInformation)}
      </Text>
      <Text style={isTotal ? styles.singleCell : styles.singleCellInner}>
        {valueHandler(row?.perMW, valueFormater, currencyInformation)}
      </Text>
    </View>
  )
}

const Section: React.FC<CellProps> = ({
  items,
  category,
  valueFormater,
  currencyInformation
}) => {
  const rowIndex = 1
  const rows =
    items &&
    Object.keys(items)
      .filter(key => key !== 'totalCosts')
      .map(key => [key, items[key]])

  return (
    <View wrap={false}>
      <View key={rowIndex} style={styles.singleCellRow}>
        <Text style={styles.singleCell}>{costLabels[category]}</Text>
        <Text style={styles.singleCell}>
          {valueHandler(
            items?.totalCosts?.absolute,
            valueFormater,
            currencyInformation
          )}
        </Text>
        <Text style={styles.singleCell}>
          {valueHandler(
            items?.totalCosts?.perTurbine,
            valueFormater,
            currencyInformation
          )}
        </Text>
        <Text style={styles.singleCell}>
          {valueHandler(
            items?.totalCosts?.perMW,
            valueFormater,
            currencyInformation
          )}
        </Text>
      </View>
      {rows?.map?.((cell, colIndex) => (
        <Row
          row={cell[1]}
          key={colIndex}
          category={cell[0]}
          valueFormater={valueFormater}
          currencyInformation={currencyInformation}
        />
      ))}
    </View>
  )
}
const ExpandedCostTable: React.FC<Props> = ({
  costs,
  name,
  description,
  headers,
  valueFormater,
  breakPage,
  currencyInformation,
  scenario
}) => {
  const tableItems =
    costs &&
    Object.keys(costs)
      .filter(key => key !== 'isManual' && key !== 'updatedAt')
      .filter(key =>
        scenario?.includeScrapValue
          ? key !== 'totalCosts'
          : key !== 'metalScrapValues' && key !== 'netTotalCosts'
      )
      .filter(key =>
        scenario?.includeContingencyAllocation
          ? key
          : key !== 'contingencyAllocation'
      )
      .map(key => [key, costs[key]])

  return (
    <View style={styles.section} wrap={breakPage}>
      <View style={styles.name}>
        <Text>{name}</Text>
        {!!description && <Text>{description}</Text>}
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          {/* Render table headers */}
          {headers?.map?.((header, index) => (
            <Text key={index} style={styles.tableColHeader}>
              {header}
            </Text>
          ))}
        </View>
        {tableItems?.map?.((section, rowIndex) => {
          const hasSubcosts =
            !!section && Object.keys(section[1]).includes('absolute')
          return hasSubcosts ? (
            <Row
              key={rowIndex}
              category={section[0]}
              row={section[1]}
              isTotal={
                section[0] === 'netTotalCosts' || section[0] === 'totalCosts'
              }
              valueFormater={valueFormater}
              currencyInformation={currencyInformation}
            />
          ) : (
            <Section
              key={rowIndex}
              category={section[0]}
              items={section[1]}
              valueFormater={valueFormater}
              currencyInformation={currencyInformation}
            />
          )
        })}
      </View>
    </View>
  )
}
export default ExpandedCostTable
